import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  width: 90%;
  margin: auto;
`
export const Header = styled.h1`
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${(props) => (props.center ? props.theme.blueDark : props.theme.grayDark)};
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    text-align: ${(props) => (props.center ? 'left' : 'center')};
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 36px;
    line-height: 40px;
    text-align: left;
  }
`
export const Subheader = styled.p`
  color: ${(props) => props.theme.grayDark};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`
export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin: 40px auto;
  @media (max-width: ${breakpoints.l}px) {
    gap: 20px;
    grid-template-columns: 1fr;
    div:nth-child(1) {
      grid-row: ${(props) => (props.reverse ? 2 : 1)};
    }
    div:nth-child(2) {
      grid-row: ${(props) => (props.reverse ? 1 : 2)};
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 20px 0px;
  }
`
export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    text-align: left;
  }
`
export const RetailNote = styled.div`
  background-color: #fdd4c9;
  padding: 16px 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  max-width: 650px;
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
    padding: 16px 24px;
    border-radius: 20px;
  }
`
export const RetailText = styled.p`
  font-family: 'FilsonProRegularItalic';
  margin: 0px 0px 0px 12px;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 16px;
  }
`
export const RetailImageContainer = styled.div`
  width: 90px;
  min-width: 64px;
`
export const TextContainer = styled.div`
  position: absolute;
  top: 20%;
  max-width: 600px;
  padding-left: 5%;
  @media (max-width: 1300px) {
    max-width: 400px;
  }
  @media (max-width: ${breakpoints.l}px) {
    max-width: 350px;
    padding-left: 2%;
  }
  @media (max-width: ${breakpoints.m}px) {
    max-width: 100%;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (max-width: ${breakpoints.s}px) {
    top: 32px;
  }
`
export const HeroContainer = styled.div`
  position: relative;
`
export const LogoContainer = styled.div`
  width: 400px;
  @media (max-width: 1300px) {
    width: 280px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 220px;
    line-height: 26px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 260px;
    max-width: 90%;
  }
  @media (max-width: 350px) {
    width: 180px;
    max-width: 90%;
  }
`
export const HeroImageContainer = styled.div`
  div:nth-child(2) {
    display: none;
  }
  @media (max-width: ${breakpoints.m}px) {
    > div:first-child {
      display: none;
    }
    div:nth-child(2) {
      display: block;
    }
  }
`
export const Text = styled.p`
  font-size: 28px;
  font-family: 'MatterRegular';
  line-height: 33.6px;
  margin: 16px 0px;
  @media (max-width: 1300px) {
    font-size: 20px;
  }
  @media (max-width: ${breakpoints.l}px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 28px;
    padding-top: 8px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: auto;
    font-size: 18px;
  }
`
