import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

import Analytics from 'src/utils/Analytics'
import StoreContext from '../../../context/StoreContext'
import {
  Container,
  Header,
  Subheader,
  GridContainer,
  Column,
  RetailNote,
  RetailText,
  RetailImageContainer,
  HeroContainer,
  TextContainer,
  LogoContainer,
  HeroImageContainer,
  Text,
} from './styles'

import { IN_CA } from '../../../utils/constants'

import Matrix from '../../Matrix'
import { CalPlusAddToCartButton } from '../CalPlusAddToCartButton'

const CalPlusHero = ({ product, retailPage }) => {
  const { calHero, calHeroMobile, logo } = useStaticQuery(graphql`
    query calHeroQuery {
      calHero: file(relativePath: { eq: "cal-plus/cal-plus-hero-retail.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calHeroMobile: file(relativePath: { eq: "cal-plus/cal-plus-hero-retail-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      logo: file(relativePath: { eq: "cal-plus/skylight-logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)
  const PLUS_PRICE = IN_CA ? 109.99 : 79.99

  const handleAddToCart = async (variantId, quantity) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Calendar Plus to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        navigate('/cart/')
      }
    }
  }
  return (
    <>
      {retailPage && (
        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage image={calHero.childImageSharp.gatsbyImageData} alt="Cal Plus" />
            <GatsbyImage image={calHeroMobile.childImageSharp.gatsbyImageData} alt="Cal Plus" />
          </HeroImageContainer>
          <TextContainer>
            <LogoContainer>
              <GatsbyImage image={logo.childImageSharp.gatsbyImageData} alt="Skylight Logo" />
            </LogoContainer>
            <Text>Technology designed for your home, organization built for your family.</Text>
          </TextContainer>
        </HeroContainer>
      )}
      <Container>
        <GridContainer>
          <Column>
            <div>
              <Header>Calendar is better with Plus</Header>
              <Subheader>
                The easiest way to plan <em>everything</em>. Instantly populate meals for the week,
                automatically import events from emails, and set your family up for success.
              </Subheader>
              {retailPage ? (
                <RetailNote>
                  <RetailImageContainer>
                    <StaticImage
                      src="../../../images/cal-plus/retail-app-logo.webp"
                      alt='A person wearing a teal sweater is holding a smartphone displaying an email with an event flyer titled "Back to School Bash."'
                      layout="fullWidth"
                      placeholder="none"
                    />
                  </RetailImageContainer>
                  <RetailText>
                    Upgrade to Calendar Plus for just ${PLUS_PRICE}/year from inside the free
                    Skylight App
                  </RetailText>
                </RetailNote>
              ) : (
                <CalPlusAddToCartButton
                  product={product}
                  adding={submitting}
                  onClick={() => handleAddToCart(product.variants[0].shopifyId, 1)}
                />
              )}
            </div>
          </Column>
          <Column>
            <Matrix retailPage={retailPage} />
          </Column>
        </GridContainer>
      </Container>
    </>
  )
}

CalPlusHero.propTypes = {
  product: PropTypes.object.isRequired,
  retailPage: PropTypes.bool,
}

export default CalPlusHero
