import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from 'react-helmet'

import SEO from 'src/components/seo'
import Analytics from 'src/utils/Analytics'
import CalPlusHero from '../../components/CalPlus/CalPlusHero'
import CalPlusFeatures from '../../components/CalPlus/CalPlusFeatures'

class UpsellProductPage extends React.Component {
  componentDidMount() {
    const {
      data: { digitalProduct },
      pageContext,
    } = this.props
    const retailPage = pageContext?.layout === 'retail'
    Analytics.track(
      `Viewed ${retailPage ? 'Retail ' : ''}${
        Analytics.trackingConstants[digitalProduct.handle] || digitalProduct.handle
      } Product Page`
    )
  }

  render() {
    const { data, t, pageContext } = this.props
    const retailPage = pageContext?.layout === 'retail'
    const product = data.digitalProduct
    const upsellConstants = {
      seoTitle: {
        'calendar-skylight-plus':
          'Digital Calendar with Photo Display | Skylight Calendar Photo Plus',
      },
      seoDescription: {
        'calendar-skylight-plus':
          'Turn your Calendar into a photo frame and seamlessly pull in events from emails or PDFs with Magic Import.',
      },
    }
    return (
      <>
        {retailPage && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <SEO
          title={t(upsellConstants.seoTitle[product.handle]) || product.title}
          description={t(upsellConstants.seoDescription[product.handle])}
          path={`/products/${product.handle}/`}
          imagePath={product.images[0].gatsbyImageData.images.fallback.src}
        />
        <CalPlusHero product={product} retailPage={retailPage} />
        <CalPlusFeatures product={product} retailPage={retailPage} />
      </>
    )
  }
}

UpsellProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func,
  pageContext: PropTypes.object,
}

export const query = graphql`
  query ($handle: String!, $language: String!) {
    digitalProduct: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      variants {
        id
        title
        price
        shopifyId
      }
      images {
        id
        gatsbyImageData
      }
    }
    locales: allLocale(filter: { ns: { in: ["common", "upsell"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default withTranslation()(UpsellProductPage)
