import React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSkylightPlusVariantFromHandle } from './useSkylightPlusVariantFromHandle'
import AddToCartButton from '../AddToCartButton'

export function CalPlusAddToCartButton({ product, adding = false, onClick }) {
  const { t } = useTranslation('common')

  const { label: addToCartButtonLabel = 'Get Plus for $79/year', disclaimer } =
    useSkylightPlusVariantFromHandle(product.handle)

  return (
    <CtaButtonContainer>
      <AddToCartButton
        addingLabel="Adding Calendar Plus..."
        widerButton
        adding={adding}
        handleClick={onClick}
        label={t(addToCartButtonLabel)}
        textcolor="#444444"
        backgroundColor="#FED597"
      />

      {!!disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
    </CtaButtonContainer>
  )
}

CalPlusAddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  adding: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

const CtaButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: ${breakpoints.l}px) {
    width: 70%;
    margin: auto;
    > button {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.m}px) {
    width: 70%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
  @media (max-width: 350px) {
    .checkout {
      text-indent: -9999px;
      line-height: 0;
    }
    .checkout:after {
      content: 'No thanks, checkout';
      text-indent: 0;
      display: block;
      line-height: initial;
    }
  }
`

const Disclaimer = styled.p`
  font-family: 'FilsonProRegularItalic';
  font-size: 15px;
  line-height: 22px;
  margin-top: 16px;
  text-align: center;

  @media (max-width: ${breakpoints.m}px) {
    align-self: center;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 20px;
  }
`
