import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import Analytics from 'src/utils/Analytics'
import { StaticImage } from 'gatsby-plugin-image'
import { FeatureContainer, DiscountDisclaimer } from '../../FramePlusFeatures/styles'
import { Container, Header, GridContainer, Column } from '../CalPlusHero/styles'
import StoreContext from '../../../context/StoreContext'
import { SecondaryHeader, CalPlusImage } from './styles'
import { CalPlusAddToCartButton } from '../CalPlusAddToCartButton'

const CalPlusFeatures = ({ product, retailPage }) => {
  const { addToCart } = useContext(StoreContext)
  const [submitting, setSubmitting] = useState(false)
  const handleAddToCart = async (variantId, quantity) => {
    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added Calendar Plus to Cart`)
      const items = [{ variantId, quantity }]
      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        navigate('/cart/')
      }
    }
  }

  return (
    <FeatureContainer color="#dcf1f3">
      <Container>
        <Header center>Calendar Plus benefits include:</Header>
        <GridContainer reverse>
          <Column>
            <div>
              <SecondaryHeader>Sidekick: Magic Family Assistant</SecondaryHeader>
              <p>
                Let Sidekick handle every school email, reminder, and flier. Magically import events
                by simply forwarding school emails, generate custom recipes, and create lists — all
                in a matter of seconds.
              </p>
            </div>
          </Column>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/cal-plus/magic-import.png"
                alt='A person wearing a teal sweater is holding a smartphone displaying an email with an event flyer titled "Back to School Bash."'
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
        </GridContainer>
        <GridContainer>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/cal-plus/meal-planning.png"
                alt="Meals planned on a skylight calendar for the week"
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
          <Column>
            <div>
              <SecondaryHeader>Smart Meal Planning</SecondaryHeader>
              <p>
                Plan a week’s worth of meals in 5 minutes, then share them with the whole family on
                a single screen. Now everyone knows what’s for dinner.{' '}
              </p>
            </div>
          </Column>
        </GridContainer>
        <GridContainer reverse>
          <Column>
            <div>
              <SecondaryHeader>Photo & Video Screensaver</SecondaryHeader>
              <p>
                Turn your Calendar into a photo frame when it’s not in use. Upload photos and
                videos, and captions from the Skylight App.
              </p>
            </div>
          </Column>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/cal-plus/screensaver.png"
                alt="A photo frame mounted on a wall displays an image of a smiling baby sitting on a couch, holding a relaxed tabby kitten"
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
        </GridContainer>
        <GridContainer>
          <Column>
            <CalPlusImage>
              <StaticImage
                src="../../../images/cal-plus/rewards.png"
                alt="Rewards feature on Skylight Calendar with star animation"
                layout="fullWidth"
                placeholder="none"
              />
            </CalPlusImage>
          </Column>
          <Column>
            <div>
              <SecondaryHeader>Rewards</SecondaryHeader>
              <p>
                Keep track of your family’s milestones in one place. Add stars to Chores to motivate
                your kids to complete tasks, earn Rewards, and build healthy habits.
              </p>
            </div>
          </Column>
        </GridContainer>
        {!retailPage && (
          <>
            <Column>
              <CalPlusAddToCartButton
                product={product}
                adding={submitting}
                onClick={() => handleAddToCart(product.variants[0].shopifyId, 1)}
              />
            </Column>

            <DiscountDisclaimer>
              Individual Calendar Plus subscriptions are excluded from marketing promotions and
              discounts.
            </DiscountDisclaimer>
          </>
        )}
      </Container>
    </FeatureContainer>
  )
}

CalPlusFeatures.propTypes = {
  product: PropTypes.object.isRequired,
  retailPage: PropTypes.bool,
}

export default CalPlusFeatures
